.home-logo {
  height: 50px;
  margin: 30px 0 0 40px;
}
body.dark-mode .modal-title {
  color: #ffffff; /* White text */
}

.register-container,
.home-container {
  text-align: left;
  font-family: 'Roboto', sans-serif;
}

.home-container {
  background-image: radial-gradient(
    circle at 30% 35%,
    rgba(139, 0, 103, 0.4),
    rgba(1, 93, 170, 0.4)
  );
  min-height: 100vh;
}

.home-text-container {
  color: #ffffff;
  margin: 8% 0 0 10%;
}

#home-welcome {
  font-size: 3.5vw;
  font-weight: 500;
  margin: 0;
}

#home-description {
  width: 45vw;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  text-shadow: 0.4px 0.4px #ababab;
}

#home-welcome,
#home-description,
.home-logo {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  pointer-events: none;
}

.home-button-container {
  position: absolute;
  display: flex;
  text-align: left;
  left: 10%;
  bottom: 35%;
}

.home-button {
  width: 130px;
  color: #ffffff;
  font-size: 21px;
  font-weight: 500;
  border-radius: 50px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
}

#signin-button {
  background-color: #2b2b2b;
  border: none;
  cursor: pointer;
  padding: 10px 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

#signin-button:hover {
  background-color: rgba(255, 255, 255, 0.5);
  color: #2b2b2b;
}

@media (max-width: 600px) {
  #home-welcome {
    font-size: 30px;
    padding-top: 20px;
    text-align: center;
  }

  .image-container {
    display: none;
  }

  .home-text-container {
    width: 70%;
    margin: 30px auto;
  }
  #home-description {
    font-size: 17px;
    text-align: center;
    width: 100%;
  }

  .home-button-container {
    bottom: 80px;
    width: 100%;
    justify-content: center;
  }

  #signin-button {
    margin-right: 100px;
    margin-bottom: 10px;
    font-size: 18px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  #home-welcome {
    font-size: 3.5vw;
  }

  #home-description {
    font-size: 20px;
  }
  .image-container {
    display: none;
  }

  .home-button-container {
    bottom: 200px;
    right: 8%;
  }

  #signin-button {
    font-size: 19px;
  }
}

@media (min-width: 1024px) and (max-width: 1130px) {
  #home-description {
    width: 300px;
    font-size: 20px;
  }
  .image-container {
    width: 500px;
  }

  .home-button-container {
    bottom: 150px;
    right: 8%;
  }

  #signin-button {
    font-size: 20px;
  }
}

@media (min-width: 1130px) and (max-width: 1340px) {
  #home-description {
    width: 400px;
    font-size: 20px;
  }
  .image-container {
    width: 500px;
  }

  .home-button-container {
    bottom: 200px;
    right: 8%;
  }
}

@media (min-width: 1025px) {
  .home-button-container {
    right: 10%;
  }
}

@media (min-width: 900px) and (min-height: 630px) {
  #signin-button {
    position: absolute;
    bottom: -330px;
    margin-bottom: 200px;
  }
}
