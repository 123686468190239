.all-courses-list {
  margin: 0 10%;
}

.upload-button,
.delete-button {
  width: 80px;
  margin: 0 auto;
  color: #2b2b2b;
  padding: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.delete-button {
  margin: 0;
  width: 135px;
  color: red;
  border: 2px solid red;
  background-color: #ffffff;
}

.upload-button:hover {
  color: white;
  background-color: #2b2b2b;
}

.delete-button:hover {
  color: white;
  background-color: #cc0000;
  border: 2px solid #cc0000;
}

.manage-button {
  align-items: center;
  width: 120px;
  margin: 0 0px 0 0;
  color: #ffffff;
  padding: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.manage-button i {
  margin-right: 8px;
  font-size: 18px;
}

.manage-button:hover {
  color: #2b2b2b;
  background-color: transparent;
}

/* CourseContent.js */
.accordion-item {
  position: sticky;
}
.course-header {
  display: flex;
  justify-content: space-between;
}
.fa-thumbtack {
  padding-top: 3px;
  font-size: 17px;
  padding-right: 18px;
  color: #7c7c7c;
}

.fa-thumbtack:hover {
  color: #000000;
  transform: scale(1.03);
}

.course-content-list {
  width: 45%;
  left: 1%;
  margin-right: 20px;
  display: none;
  min-width: 200px;
  position: absolute;
  z-index: 3;
  /* z-index: 9998; */
  max-height: 80vh;
  overflow-y: auto;
}

.course-content-list2 {
  width: 50% !important;
  margin-right: 20px;
  min-width: 50%;
  max-height: 80vh;
  overflow-y: auto;
}
.show {
  display: block;
}
#myProgress-connect-button,
#myProgress-reset-button {
  padding: 4px 15px;
  margin: 0 10px 10px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  background-color: #2b2b2b;
  color: white;
  border: 2px solid #2b2b2b;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

#myProgress-connect-button:hover,
#myProgress-reset-button:hover {
  background-color: transparent;
  color: #2b2b2b;
  border: 2px solid #2b2b2b;
}

.mute-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#showContent {
  /* padding: 5px 20px; */
  display: block;
  z-index: 1000;
  position: absolute;
  left: 100px;
  top: 94px;
  color: #191818;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: transparent;
  padding-bottom: 13px;
  padding-left: 20px;
}

#showContent i {
  margin-right: 8px;
  font-size: 18px;
}

#showContent:hover i,
#showContent:hover span {
  color: #535353;
}
body.dark-mode #showContent:hover i,
#showContent:hover span {
  color: #a7a7a7;
}
#myProgress-mute-button {
  padding: 5px 20px;
  background-color: transparent;
  color: #2b2b2b;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid #2b2b2b;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

#myProgress-mute-button:hover {
  background-color: #2b2b2b;
  color: white;
}
.myProgress-input-container {
  padding-bottom: 1.2em;
  display: flex;
  flex-direction: row;
}

/* FeedbackList.js */

.feedback-heading {
  width: 120px;
  color: #777777;
  font-size: 15px;
}

.bg-rating-bar {
  background-image: linear-gradient(
    135deg,
    rgba(139, 0, 103, 0.4),
    rgba(1, 93, 170, 0.4)
  ) !important;
  background-color: white !important;
}

.feedback-student-name {
  font-size: 17px;
}

.feedback-submitted-at {
  font-size: 15px;
  font-weight: 500;
  color: #777777;
}

.feedback-search-button {
  align-items: center;
  width: 110px;
  color: #ffffff;
  padding: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.feedback-search-button i {
  margin-right: 8px;
  font-size: 18px;
}

.feedback-search-button:hover {
  color: #2b2b2b;
  background-color: transparent;
}

body.dark-mode .feedback-search-button {
  color: #ffffff;
  background-color: #2b2b2b;
  border: 2px solid #5f5f5f;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode .feedback-search-button:hover {
  color: #000;
  background-color: #5f5f5f;
}

body.dark-mode .feedback-search-button:disabled {
  color: #777777;
  background-color: #444444;
  cursor: not-allowed;
}

/* ContentModal.js */

.oral-quiz-setting {
  display: flex;
  justify-content: flex-end;
}

.edit-button,
.save-button {
  width: 70px;
  color: #ffffff;
  padding: 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  background-color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.edit-button:hover,
.save-button:hover {
  background-color: #ffffff;
  color: #2b2b2b;
}

/* CourseStuentList.js */

.all-students-list {
  width: 80%;
  margin: 0 auto;
}

.list-student-analytics-button {
  align-items: center;
  width: 160px;
  margin: 0 20px 0 0;
  color: #ffffff;
  padding: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.list-student-analytics-button i {
  margin-right: 8px;
  font-size: 18px;
}

.list-student-analytics-button:hover {
  color: #2b2b2b;
  background-color: transparent;
}

@media (max-width: 1120px) {
  .course-content-list {
    min-width: 300px;
    position: absolute;
    z-index: 3;
  }
  #showContent {
    padding-bottom: 15px;
    display: block;
    z-index: 1000;
    position: absolute;
    left: 100px;
    top: 90px;
  }

  .myProgress-switch-button {
    display: none;
  }
  .view-student-feedback-btn::after {
    content: 'View Feedback';
  }
}

@media (max-width: 576px) {
  #showContent {
    padding-bottom: 15px;
    display: block;
    z-index: 1000;
    position: absolute;
    left: 100px;
    top: 90px;
  }
  #showContent span {
    display: none;
  }

  #showContent i {
    margin-right: 0;
    font-size: 22px;
  }

  .all-students-list {
    width: 90%;
    margin: 0 auto;
  }
  .view-student-feedback-btn::after {
    content: 'Feedback';
  }
}

.myProgress-switch-button .form-check-input {
  width: 4em;
  height: 1.3em;
  border: none;
}

.myProgress-switch-button .form-check-input:checked {
  background-color: rgba(139, 0, 103, 0.4);
  border-color: rgba(139, 0, 103, 0.4);
}

.myProgress-switch-button .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(139, 0, 103, 0.4);
}

.myProgress-switch-button .form-check-label {
  font-weight: bold;
  user-select: none;
  -webkit-user-drag: none;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.edit-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-button2,
.save-button2 {
  width: 70px;
  color: #ffffff;
  padding: 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  background-color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.edit-button2:hover,
.save-button2:hover {
  background-color: transparent;
  color: #2b2b2b;
}

@media (max-width: 450px) {
  .myProgress-switch-button .short-label {
    display: inline;
  }

  .myProgress-switch-button .long-label {
    display: none;
  }
}

@media (min-width: 451px) {
  .myProgress-switch-button .short-label {
    display: none;
  }

  .myProgress-switch-button .long-label {
    display: inline;
  }
}

@keyframes wave-animation {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1.5);
  }
  100% {
    transform: scaleY(1);
  }
}

.wave-bars {
  display: flex;
  gap: 4px;
  position: absolute;
  bottom: 82px;
  right: 15px;
  padding: -10px;
}

.wave-bars .bar {
  width: 4px;
  height: 20px;
  background-color: rgba(139, 0, 103, 0.8);
  animation: wave-animation 0.8s infinite;
}

.wave-bars2 {
  display: flex;
  gap: 4px;
  position: absolute;
  bottom: 22px;
  right: 15px;
  padding: -10px;
}

.wave-bars2 .bar {
  width: 4px;
  height: 20px;
  background-color: rgba(139, 0, 103, 0.8);
  animation: wave-animation 0.8s infinite;
}

.bar:nth-child(2) {
  animation-delay: 0.1s;
}

.bar:nth-child(3) {
  animation-delay: 0.2s;
}

.bar:nth-child(4) {
  animation-delay: 0.3s;
}

.icon-button {
  background-color: white;
  border: 2px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.loading-screen {
  height: auto;
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: 'translate(-50%, -50%)';
  width: 100px;
  z-index: 10;
}

@keyframes breathing {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.breathing-img {
  animation: breathing 2s ease-in-out infinite;
}

/* Accordion dark mode styling */
body.dark-mode .accordion-item {
  background-color: #2b2b2b;
  color: #ffffff;
  border: 1px solid #444444;
}

body.dark-mode .accordion-button {
  background-color: #2b2b2b;
  color: #ffffff;
  border: 1px solid #444444;
}

body.dark-mode .accordion-button:not(.collapsed) {
  background-color: #444444;
  color: #ffffff !important;
}

body.dark-mode .list-group-item {
  background-color: #2b2b2b;
  color: #ffffff !important;
  border-color: #444444;
}

body.dark-mode .list-group-item:hover {
  background-color: #444444;
}

body.dark-mode .myProgress-soulMachines-container {
  background-color: transparent;
  color: #ffffff;
}

.myProgress-soulMachines-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0;
  /* padding: 10px; */
  box-sizing: border-box;
}
.myProgress-layout2 {
  margin: 0;
  width: 100%;
  padding-top: 15px;
}

body.dark-mode .myProgress-chat-container {
  /* background-color: #2b2b2b; */
  color: #ffffff;
}

body.dark-mode .myProgress-input-container input {
  border: 2px #404040 solid;
  background-color: #191818;
  color: #e0dfdf;
}

/* Dark mode check icon styles */
body.dark-mode .fa-circle-check {
  color: #ffffff !important; /* Green for completed icon */
}

body.dark-mode .fa-circle {
  color: #ffffff !important; /* Gray for not completed icon */
}

body.dark-mode #send_message_soul_machine {
  background-color: #000 !important;
  color: white !important;
}

body.dark-mode .send-button {
  background-color: #000000 !important;
  color: #fff !important;
}

.send-button:hover {
  background-color: #555 !important;
  color: #fff !important;
}

body.dark-mode .course-content-list .module-text {
  color: #ffffff !important;
  font-weight: bold !important;
}
body.dark-mode .module-text {
  color: #ffffff !important;
  font-weight: bold !important;
}

body.dark-mode .myProgress-input-container input {
  border: 1px #404040 solid !important;
  background-color: #191818 !important;
  color: #e0dfdf !important;
}

body.dark-mode .list-student-analytics-button {
  background-color: #444444;
  color: #ffffff;
  border: 1px solid #555555;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

body.dark-mode .list-student-analytics-button:hover {
  background-color: #555555;
  color: #ffffff;
  border-color: #666666;
}

body.dark-mode .fa-regular {
  color: #ffffff;
}

body.dark-mode .fa-regular:hover {
  color: #d0d0ff;
}

body.dark-mode .all-students-list {
  color: #ffffff;
}

body.dark-mode .accordion {
  background-color: #333333;
  border-radius: 10px;
}

body.dark-mode .accordion-item {
  background-color: #333333;
  color: #ffffff;
  border-color: #444444;
}

body.dark-mode .accordion-header {
  background-color: #444444;
  color: #ffffff;
  font-weight: bold;
}

body.dark-mode .accordion-header:hover {
  background-color: #555555;
}

body.dark-mode .accordion-body {
  background-color: #2b2b2b;
  color: #ffffff;
}
body.dark-mode .table {
  background-color: #2b2b2b;
  color: #ffffff;
  border-color: #444444;
}

body.dark-mode .table th {
  background-color: #333333;
  color: #ffffff;
}

body.dark-mode .table-striped tbody tr:nth-of-type(odd) {
  background-color: #3b3b3b;
}

body.dark-mode .table-hover tbody tr:hover {
  background-color: #444444;
}

body.dark-mode .course-progress .selected-module {
  background-color: #333333;
  border-bottom: 1px solid #555555;
}

body.dark-mode .course-progress .expanded {
  border-bottom: 1px solid #555555;
}

body.dark-mode .fa-circle {
  color: #999999;
}

body.dark-mode .fa-chevron-down,
body.dark-mode .fa-chevron-up {
  color: #ffffff;
}

body.dark-mode .list-student-analytics-button {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #555555;
}

body.dark-mode .list-student-analytics-button:hover {
  background-color: #444444;
  color: #ffffff;
}

body.dark-mode .edit-button2,
body.dark-mode .save-button2 {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #555555;
}

body.dark-mode .edit-button2:hover,
body.dark-mode .save-button2:hover {
  background-color: #444444;
  color: #ffffff;
}

body.dark-mode .table td {
  background-color: #2b2b2b;
  color: #ffffff;
  border-color: #444444;
}

body.dark-mode .course-progress .table td {
  background-color: #2b2b2b;
  color: #ffffff;
  border-color: #444444;
}

body.dark-mode .course-progress .table td,
body.dark-mode .course-progress .table th {
  color: #ffffff;
}

/* body.dark-mode .course-progress .table td[style*='rgba(139, 0, 103, 0.8)'],
body.dark-mode .course-progress .table td[style*='color'] {
  color: rgb(133, 81, 119) !important;
}

body.dark-mode .completion-rate,
body.dark-mode .important-data {
  color: rgb(133, 81, 119) !important;
}

body.dark-mode .course-progress .table td[style*='rgba(139, 0, 103, 0.8)'],
body.dark-mode .course-progress .table td[style*='color'] {
  color: rgb(133, 81, 119) !important;
} */
.feedback {
  background-color: #ffffff !important;
  color: #4b4b4b9e !important;
  border: 1px #4040401f solid !important;
}

body.dark-mode .form-select {
  border: 2px #404040 solid !important;
  background-color: #191818 !important;
  color: #e0dfdf !important;
}

body.dark-mode {
  color: #ffffff !important;
}

body.dark-mode .white {
  color: #ffffff !important;
}

body.dark-mode .white2 {
  color: #ffffff !important;
}
.white2:hover {
  background-color: rgb(135, 26, 106) !important;
  color: #ffffff !important;
}
body.dark-mode .white2:hover {
  background-color: rgb(255, 255, 255) !important;
  color: #2b2b2b !important;
}
body.dark-mode a {
  color: #ffffff !important;
}

body.dark-mode .upload-button {
  color: #ffffff;
  border: 2px #828282 solid;
  background-color: #828282;
}

body.dark-mode .upload-button:hover {
  border: 2px #828282 solid;
  background-color: transparent;
  color: #ffffff;
}

body.dark-mode .manage-button:hover {
  color: #2b2b2b !important;
  background-color: #ffffff !important;
}

body.dark-mode .manage-button {
  border: 2px solid #ffffff !important;
}

body.dark-mode .white {
  color: #ffffff !important;
}

body.dark-mode .white2 {
  color: #ffffff !important;
}

body.dark-mode .course-background {
  background-color: transparent !important;
}

body.dark-mode .feedback-list {
  background-color: transparent !important;
}

body.dark-mode .feedback-student-name {
  color: white !important;
}

body.dark-mode b {
  color: white !important;
}

body.dark-mode .help-content-container {
  background-color: transparent !important;
}

body.dark-mode .delete-button {
  margin: 0;
  width: 135px;
  color: rgba(255, 0, 0, 0.694);
  border: 2px solid rgba(255, 0, 0, 0.694);
  background-color: rgba(255, 255, 255, 0);
}
body.dark-mode .delete-button:hover {
  color: rgb(255, 255, 255);
  background-color: rgba(255, 0, 0, 0.569);
  border: 2px solid rgba(255, 0, 0, 0.569);
}
/* 
.training-button {
  align-items: center;
  width: 160px;
  color: #ffffff;
  padding: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.training-button i {
  margin-right: 8px;
  font-size: 18px;
}

.training-button:hover {
  color: #2b2b2b;
  background-color: transparent;
}

body.dark-mode .training-button {
  background-color: #444444;
  color: #ffffff;
  border: 1px solid #555555;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
body.dark-mode .training-button:hover {
  background-color: #555555;
  color: #ffffff;
  border-color: #666666;
  border-color: rgba(139, 0, 103, 0.4);

}
*/

/*placeholder-direct feedback*/

textarea {
  padding: 10px 8px;
  width: 100%; /* Adjust to fit your design */
  height: auto;
  border: 1px dashed #ccc;
  border-radius: '4px';
  min-height: 50px; /* Adjust this as needed */
  max-width: 100%; /* Prevent overflow */
  box-sizing: border-box; /* Include padding and border in width/height */
  word-wrap: break-word; /* Ensure text wraps to the next line */
  overflow-wrap: break-word;
  white-space: pre-wrap; /* Preserve line breaks if needed */
  resize: vertical; /* Allow the user to adjust the height */
}

@media (max-width: 900px) {
  .feedback-row {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .topic-feedback-container {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 650px) {
  .loading-screen {
    top: 50%;
    left: 50%;
    transform: 'translate(-50%, -50%)';
    width: 100px;
    z-index: 10;
  }
}

.back-button {
  background-color: transparent;
  align-items: center;
  border: none;
  margin-top: -5px;
  margin-left: 5px;
  color: #2b2b2b;
  padding-bottom: 2px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: none;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.back-button i {
  margin-right: 8px;
  font-size: 18px;
}

.back-button:hover {
  color: #777777;
}

body.dark-mode .back-button {
  background-color: transparent;
  color: #ffffff;
}

body.dark-mode .back-button:hover {
  color: #cccccc;
}

.view-student-feedback-btn::after {
  content: attr(data-text);
}

.accordion-button::after {
  filter: invert(0%);
}

body.dark-mode .accordion-button::after {
  filter: invert(100%) !important;
}
body.dark-mode .kb-ai-dropdown {
  border: 2px #404040 solid !important;
  background-color: #1918189b !important;
  color: #e0dfdf !important;
}

button[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 15px;
  white-space: nowrap;
  display: none;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
}

button[data-tooltip]:hover::after {
  display: block;
}

body.dark-mode #interrupt_avatar {
  background-color: transparent;
  color: #ffffff87;
  border: 2px solid #ffffff29;
  border-radius: 5px;
}

@media (max-width: 660px) {
  .training-button .button-text {
    display: none;
  }
}
@media (max-width: 725px) {
  .training-button .button-text {
    display: none;
  }
}

input[type='date'] {
  background-color: #8a8a8a16 !important;
  color: #777 !important;
}

.upload-button2 {
  color: white;
  background-color: #2b2b2b;
  width: 80px;
  padding: 5px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.upload-button2:hover {
  color: #2b2b2b;
  background-color: #ffffff;
}

body.dark-mode .upload-button2 {
  color: #ffffff;
  border: 2px #828282 solid;
  background-color: #828282;
}

body.dark-mode .upload-button2:hover {
  border: 2px #828282 solid;
  background-color: transparent;
  color: #ffffff;
}

@media (max-width: 460px) {
  .training-button {
    position: relative;
    left: 15px;
  }
  .tr {
    position: relative;
    left: 13px;
  }
}

@media (max-width: 425px) {
  .training-button {
    position: relative;
    left: 15px;
  }
  .tr {
    position: relative;
    left: 13px;
  }
}
