.logo {
  height: 50px;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  box-sizing: border-box;
}

.student-dashboard-container,
.instructor-dashboard-container {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  text-align: center;
  min-height: 100vh;
}

.student-view-top-container,
.instructor-view-top-container {
  display: flex;
  align-items: center;
  padding: 14px 0 0 40px;
  justify-content: space-between;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
}

.logo-container {
  margin-right: 40px;
}

.content-container {
  text-align: left;
  width: 100%;
  margin: 30px auto;
}

.page-title {
  margin: 0 10%;
  padding-bottom: 1.2em;
  color: #2b2b2b;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  pointer-events: none;
}
/*dark mode*/
body.dark-mode,
html.dark-mode {
  background-color: #1e1e1e;
  color: #e0e0e0;
}

body.dark-mode .student-dashboard-container,
body.dark-mode .instructor-dashboard-container {
  background-color: #1e1e1e;
  color: #e0e0e0;
}
body.dark-mode .page-title {
  color: #e2e2e2;
}

/* Navigation Bar */

.nav-bar-container {
  display: flex;
  align-items: center;
}

.profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-left: auto;
  margin-right: 20px;
}

.profile-user-button {
  background-color: transparent;
  color: #2b2b2b;
  font-size: 16px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.profile-img {
  background-color: transparent;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
  cursor: pointer;
}

.profile-user-button:hover {
  color: #4a4a4a;
}

.signout-container {
  margin-left: 20px;
  margin-right: 30px;
}
/* dark mode */
body.dark-mode {
  background-color: #2b2b2b;
  color: #ffffff;
}

body.dark-mode .profile-container {
  background-color: transparent;
}

body.dark-mode .profile-user-button {
  color: #ffffff;
}

body.dark-mode .profile-user-button:hover {
  color: #d1d1d1;
}

body.dark-mode .profile-img {
  background-color: #666666;
}

/* Students: Feedback page */

.feedback-form-container {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  margin: 40px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.feedback-form-container form {
  margin: 0 10%;
}

.feedback-form-container h2 {
  text-align: center;
  margin: 20px 0 80px 0;
}

.feedback-form-container p {
  font-size: 18px;
  font-weight: 500;
  color: #2b2b2b;
  margin-bottom: 15px;
  text-align: left;
}

.feedback-icons {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin: 20px 0 40px 0;
}

.feedback-icons i {
  font-size: 2rem;
  cursor: pointer;
  color: #a0a0a0;
  padding: 0px 7px;
}

.feedback-icons i.selected {
  color: #9a3584;
}

.separator {
  border-top: 1px solid #ccc;
  margin: 10px 0 40px 0;
}

.feedback-textarea {
  width: 100%;
  font-family: inherit;
  height: 150px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  font-size: 16px;
}

.char-count {
  font-size: 14px;
  color: #777777;
  text-align: right;
}

.feedback-submit-button {
  width: 120px;
  margin: 10px auto 0 auto;
  padding: 8px 30px;
  font-size: 16px;
  font-weight: 510;
  border: 2px #2b2b2b solid;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  background-color: #2b2b2b;
  color: white;
  cursor: pointer;
  display: block;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.feedback-submit-button:hover {
  color: #2b2b2b;
  background-color: transparent;
}
/* Students: Feedback page (Dark Mode) */

body.dark-mode .feedback-form-container {
  background-color: #2b2b2b; /* Dark background */
  color: #ffffff; /* White text */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1); /* Softer shadow */
}

body.dark-mode .feedback-form-container h2 {
  color: #ffffff; /* White header */
}

body.dark-mode .feedback-form-container p {
  color: #cccccc; /* Lighter text for better contrast */
}

body.dark-mode .feedback-icons i {
  color: #777777; /* Neutral icon color */
}

body.dark-mode .feedback-icons i.selected {
  color: #be72a6;
}

body.dark-mode .separator {
  border-top: 1px solid #444444;
}

body.dark-mode .feedback-textarea {
  border: 1px #404040 solid !important;
  background-color: #191818 !important;
  color: #e0dfdf !important;
}

body.dark-mode .char-count {
  color: #999999;
}

/* Students: IOA Practice page */

#ioaPractice-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 70px;
  height: 80vh; */
}

#connect-button {
  background-color: rgba(0, 0, 0, 0.811);
}

#reset-button {
  background-color: rgba(139, 0, 103, 0.59);
}

#connect-button,
#reset-button {
  color: white;
  font-weight: bold;
  border: none;
  padding: 15px 25px;
  font-size: 16px;
  border-radius: 50px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

#reset-button:hover {
  /*background-color: rgba(139, 0, 103);*/
  background-color: transparent;
  color: rgba(139, 0, 103);
  border: 2px solid rgba(139, 0, 103);
}

#connect-button:hover {
  background-color: rgba(1, 93, 170);
}

.ioa-practice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.ioa-sm-title {
  font-family: inherit;
  font-size: 1.5em;
  flex: 1;
  text-align: center;
}

#change-topic-button {
  background-color: #2c2c2c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 0.9em;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

#change-topic-button:hover {
  background-color: rgba(1, 93, 170, 0.7);
}

.message-input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.message-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 70%;
  margin-right: 10px;
  font-size: 16px;
}

.send-message-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.send-message-button:hover {
  background-color: #45a049;
}

.processing-text {
  font-size: 18px;
  font-weight: bold;
  color: #555;
  margin-top: 15px;
}

#reset-button {
  padding: 10px 20px;
  background-color: transparent;
  color: #2b2b2b;
  font-size: 16px;
  border: 2px solid #2b2b2b;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

#reset-button:hover {
  background-color: transparent;
  color: rgba(139, 0, 103);
  border: 2px solid rgba(139, 0, 103);
  cursor: pointer;
}

#reset-button:active {
  background-color: rgba(139, 0, 103);
  color: white;
}

.connect-button,
.change-topic-button {
  padding: 10px 20px;
  background-color: #008cba;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.connect-button:hover,
.change-topic-button:hover {
  background-color: #007bb5;
}

.userInputAIbutton {
  background-color: #000000;
  color: white;
}

.send_message_button :hover {
  background-color: #2d2c2c;
}

#send_message_ai {
  padding: 10px 15px;
  margin-left: 5px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  width: 70px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

#send_message_ai:hover {
  background-color: #2e2c2d;
}

input#userInputAI {
  width: calc(100% - 80px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 5px;
}

input#userInputAI:focus {
  outline: none;
  border-color: #2e2c2d;
  box-shadow: 0 0 5px #2e2c2d;
}

.message-input {
  width: 250px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-right: 10px;
}

.ioa-layout {
  display: flex;
  height: 100%;
  padding: 20px;
}

#sm-video {
  background-color: #1a1a1a;
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

#chatbox2 {
  display: flex;
  flex-direction: column;
  flex-grow: 1 !important;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  list-style: none;
  height: 300px;
  flex-grow: 1;

  overflow-y: auto;
}

#chatbox {
  display: flex;
  flex-direction: column;
  flex-grow: 1 !important;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  list-style: none;
  flex-grow: 1;
  min-height: 38.4rem;
  max-height: 38.4rem;
  overflow-y: auto;
}

.chat-bubble {
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  margin: 0px 0px 5px 0px;
  border-radius: 15px;
  max-width: 80%;
  word-wrap: break-word;
  clear: both;
}

.chat-bubble.user {
  background-color: rgba(179, 51, 153, 0.2);
  align-self: flex-end !important;
  margin-left: auto;
  margin-right: 0;
}

.chat-bubble.persona {
  background-color: #e0e0e0;
  align-self: flex-start;
  margin-right: auto;
}

.persona-name {
  font-size: 13px;
  color: #888;
  margin-left: 10px;
}

.chat-bubble strong {
  color: #333;
}

.input-container {
  display: flex;
  align-items: center;
}
/* Students: IOA Practice page (Dark Mode) */

body.dark-mode #ioaPractice-content-container {
  background-color: #1a1a1a; /* Dark background */
  color: #ffffff; /* White text */
}

body.dark-mode #connect-button {
  background-color: #2e2e2e;
  color: white;
}

body.dark-mode #reset-button {
  background-color: rgba(139, 0, 103, 0.3);
  color: #ffffff;
  border: 2px solid rgba(139, 0, 103, 0.5);
}

body.dark-mode #connect-button:hover {
  background-color: #005c99; /* Lighter blue on hover */
}

body.dark-mode #reset-button:hover {
  background-color: transparent;
  color: rgba(139, 0, 103);
  border: 2px solid rgba(139, 0, 103);
}

body.dark-mode .ioa-practice-header {
  background-color: #2c2c2c;
  color: #ffffff;
}

body.dark-mode .ioa-sm-title {
  color: #ffffff;
}

body.dark-mode #change-topic-button {
  background-color: #444444;
  color: white;
}

body.dark-mode #change-topic-button:hover {
  background-color: rgba(1, 93, 170, 0.7);
}

body.dark-mode .message-input {
  background-color: #2b2b2b;
  color: #ffffff;
  border: 1px solid #555555;
}

body.dark-mode .send-message-button {
  background-color: #3c763d;
  color: #ffffff;
}

body.dark-mode .send-message-button:hover {
  background-color: #2e6e33;
}

body.dark-mode .processing-text {
  color: #cccccc;
}

body.dark-mode #send_message_ai {
  background-color: #2b2b2b;
  color: white;
}

body.dark-mode #send_message_ai:hover {
  background-color: #444444;
}

body.dark-mode input#userInputAI {
  background-color: #2b2b2b;
  color: #ffffff;
  border: 1px solid #555555;
}

body.dark-mode input#userInputAI:focus {
  border-color: #777777;
  box-shadow: 0 0 5px #777777;
}

body.dark-mode #sm-video {
  background-color: #000000;
}

body.dark-mode .chat-container {
  background-color: #1a1a1a;
  color: #ffffff;
}

body.dark-mode #chatbox {
  background-color: #2b2b2b;
  color: #ffffff;
}
body.dark-mode #chatbox2 {
  background-color: #2b2b2b;
  color: #ffffff;
}

body.dark-mode .chat-bubble.user {
  background-color: rgba(179, 51, 153, 0.3);
}

body.dark-mode .chat-bubble.persona {
  background-color: #3a3a3a;
}

body.dark-mode .persona-name {
  color: #aaaaaa;
}

body.dark-mode .chat-bubble strong {
  color: #ffffff;
}

/* Students: Help page */

.dark-mode-dropdown {
  margin: 20px 0;
  text-align: left;
}

#dropdown-darkmode {
  background-color: #313131;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 15px;
  cursor: pointer;
}

body.dark-mode #dropdown-darkmode {
  background-color: #313131;
  color: #ffffff;
}

.help-content-container {
  padding-top: 40px;
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
}

.help-header-container {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
  text-align: center;
}

.help-page-title {
  color: #2b2b2b;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #2b2b2b;
  font-weight: 500;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  pointer-events: none;
  font-family: 'Roboto', sans-serif;
}

.help-main-sections {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
  font-family: 'Roboto', sans-serif !important;
}

.help-section-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.help-section-box:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.help-section-icon {
  width: 50px;
  margin-top: 20px;
  font-size: 3rem;
  height: 50px;
  margin-bottom: 10px;
}

.help-section-box p {
  font-size: 1.2rem;
  font-weight: 400;
}

.help-getting-started {
  width: 100%;
  text-align: center;
  max-width: 1200px;
  padding-bottom: 30px;
}

.help-getting-started h2 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.help-getting-started-list {
  list-style: none;
  padding: 0;
}

.help-getting-started-list li {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.help-getting-started-list li a {
  text-decoration: none;
  color: #333;
  font-size: 1.1rem;
}

.toggle-button {
  font-weight: 600;
  width: 100%;
  padding: 15px;
  background: none;
  border: none;
  text-align: left;
  font-size: 1.1rem;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expanded-content {
  padding: 15px;
  text-align: left;
  background-color: #fff;
}

/* Students: Help page (Dark Mode) */

body.dark-mode .dark-mode-dropdown {
  text-align: left;
}

body.dark-mode #dropdown-darkmode {
  background-color: #2b2b2b; /* Darker dropdown */
  color: #ffffff; /* White text */
  border: none;
}

body.dark-mode .help-content-container {
  background-color: #1a1a1a; /* Dark background for the container */
  color: #ffffff; /* White text */
}

body.dark-mode .help-header-container {
  color: #ffffff;
}

body.dark-mode .help-page-title {
  color: #ffffff;
}

body.dark-mode .help-main-sections {
  background-color: transparent;
}

body.dark-mode .help-section-box {
  background-color: #2b2b2b;
  color: #ffffff;
  border: 1px solid #444444;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

body.dark-mode .help-section-box:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

body.dark-mode .help-section-icon {
  color: #9a9a9a;
}

body.dark-mode .help-section-box p {
  color: #dddddd;
}

body.dark-mode .help-getting-started {
  color: #ffffff;
}

body.dark-mode .help-getting-started h2 {
  color: #ffffff;
}

body.dark-mode .help-getting-started-list {
  background-color: transparent !important;
}

body.dark-mode .help-getting-started-list li {
  background-color: #2b2b2b;
  border: 1px solid #444444;
  color: #ffffff;
}

body.dark-mode .help-getting-started-list li:hover {
  background-color: transparent;
}

body.dark-mode .help-getting-started-list li a {
  color: #ffffff;
}

body.dark-mode .toggle-button {
  color: #ffffff;
}

body.dark-mode .expanded-content {
  background-color: #2b2b2b;
  color: #ffffff;
}

/* Dark Mode Styles for Bootstrap Dropdown */
body.dark-mode .dropdown-menu {
  background-color: #1a1a1a;
  border: 1px solid #444444;
}

body.dark-mode .dropdown-item {
  color: #000000;
  background-color: transparent;
  transition: color 0.3s ease, background-color 0.3s ease;
}

body.dark-mode .dropdown-item:hover {
  color: #ffffff;
  background-color: #444444;
}

body.dark-mode #dropdown-darkmode {
  background-color: #2b2b2b;
  color: #ffffff;
  border: 1px solid #444444;
  border-radius: 15px;
}

body.dark-mode #dropdown-darkmode:hover {
  background-color: #444444;
}

/* Instructors Side: Courses page */

.course-actions {
  margin: 0 10%;
}

.list-all-courses-button {
  align-items: center;
  width: 180px;
  margin-bottom: 10px;
  margin-right: 20px;
  color: #ffffff;
  padding: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.list-all-courses-button i {
  margin-right: 8px;
  font-size: 18px;
}

.list-all-courses-button:hover {
  color: #2b2b2b;
  background-color: transparent;
}

.add-course-button {
  align-items: center;
  width: 145px;
  margin: 0 20px 0 0;
  color: #2b2b2b;
  padding: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.add-course-button i {
  margin-right: 8px;
  font-size: 18px;
}

.add-course-button:hover {
  color: white;
  background-color: #2b2b2b;
}

.add-course-form {
  margin: 0 10%;
  font-size: 16px;
  font-weight: 500;
}

.add-course-submit-container {
  text-align: center;
}

.add-course-submit-button {
  align-items: center;
  width: 120px;
  margin: 0 0 20px 0;
  color: #ffffff;
  padding: 8px 30px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px #2b2b2b solid;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.add-course-submit-button:hover {
  color: #2b2b2b;
  background-color: transparent;
}

.email-add-button {
  width: 30px;
  height: 30px;
  margin: 5px 0 5px 0;
  align-items: center;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px #2b2b2b solid;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: #2b2b2b;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.email-add-button:hover {
  color: #2b2b2b;
  background-color: transparent;
}

.email-remove-button {
  width: 30px;
  height: 30px;
  margin: 10px 0 5px 0;
  align-items: center;
  color: #2b2b2b;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.email-remove-button:hover {
  color: white;
  background-color: #cc0000;
  border: 2px solid #cc0000;
}

.fa-circle-info {
  color: #777777;
  margin: 0 0 0 5px;
}
/*dark mode */
body.dark-mode .list-all-courses-button {
  color: #ffffff;
  background-color: #1a1a1a;
  border: 2px solid #444444;
}

body.dark-mode .list-all-courses-button:hover {
  color: #1a1a1a;
  background-color: #7c7b7bbf;
  border: 2px solid #1a1a1a;
}

body.dark-mode .add-course-button {
  color: #ffffff;
  background-color: #1a1a1a;
  border: 2px solid #444444;
}

body.dark-mode .add-course-button:hover {
  color: #1a1a1a;
  background-color: #7c7b7bbf;
  border: 2px solid #1a1a1a;
}

body.dark-mode .add-course-form {
  color: #ffffff;
}

body.dark-mode .add-course-submit-button {
  color: #ffffff;
  background-color: #1a1a1a;
  border: 2px solid #444444;
}

body.dark-mode .add-course-submit-button:hover {
  color: #1a1a1a;
  background-color: transparent;
  border: 2px solid #1a1a1a;
}

body.dark-mode .email-add-button {
  color: #ffffff;
  background-color: #1a1a1a;
  border: 2px solid #444444;
}

body.dark-mode .email-add-button:hover {
  color: #1a1a1a;
  background-color: #ffffff;
  border: 2px solid #1a1a1a;
}

body.dark-mode .email-remove-button {
  color: #ffffff;
  background-color: #1a1a1a;
  border: 2px solid #444444;
}

body.dark-mode .email-remove-button:hover {
  color: #ffffff;
  background-color: #cc0000;
  border: 2px solid #cc0000;
}

body.dark-mode .fa-circle-info {
  color: #bbbbbb;
}

/* Instructors Side: Feedback page */

.feedback-course-checkbox {
  margin: 0 10%;
}
.myProgress-layout2 {
  margin: 0;
  padding: 0;
}
.video-container {
  margin: 0;
  padding-top: 8px;
}
.myProgress-chat-container,
.video-container {
  width: 100%;
  max-width: 650px;
}

.video-container video {
  width: 100%;
  height: auto;
}

.video-container2 {
  margin: 0;
  padding-top: 8px;
}
.feedback-form-inst {
  margin-left: -10px;
  margin-top: -10px;
}
.feedback-form-inst form {
  width: 80%;
  margin: 0 auto;
}

.feedback-list {
  margin: 0 10%;
}

body.dark-mode .feedback-form-inst form,
body.dark-mode .feedback-list {
  color: #ffffff;
  border-radius: 5px;
  padding: 10px;
}

body.dark-mode .feedback-course-checkbox {
  color: #ffffff;
  background-color: #1a1a1a;
}

body.dark-mode .video-container {
  background-color: transparent;
  color: #ffffff;
}
/* Instructors Side: Help page */
.help-link.light {
  color: blue !important;
  text-decoration: underline !important;
}

.help-link.dark {
  color: #87ceeb !important;
  text-decoration: underline !important;
}

.help-link.dark:hover {
  color: #b0e0e6 !important;
}

/* Instructors Side: Dashboard page */

#dashboard-tabs {
  width: 200px;
  margin: 0 10%;
}

/* MyProgress.js */

#course-filter-dropdown {
  background-color: transparent;
  color: #2b2b2b;
  border: 2px solid #2b2b2b;
  padding: 6px 13px;
  font-size: 15px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: right;
  margin-left: auto;
  position: relative;
  right: 50%;
}

#course-filter-dropdown:hover {
  background-color: #2b2b2b;
  color: #fff;
  border: 2px solid #2b2b2b;
}

.back-to-course-redirect-button {
  background-color: transparent;
  align-items: center;
  margin-top: -5px;
  margin-left: 5px;
  color: #2b2b2b;
  padding-bottom: 2px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: none;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.back-to-course-redirect-button i {
  margin-right: 8px;
  font-size: 18px;
}

.back-to-course-redirect-button:hover {
  color: #777777;
}

.z-index-button {
  position: relative;
  z-index: 10;
}

.content-container form {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 576px) {
  .logo-container {
    margin-right: 0;
  }

  .profile-container {
    display: none;
  }

  .content-container form {
    width: 90%;
  }

  .page-title {
    margin: 0 5%;
  }

  .feedback-icons {
    gap: 5px;
  }

  .feedback-form-container {
    width: 90%;
  }

  .feedback-form-container form,
  .feedback-form-inst form,
  .feedback-list,
  .course-actions {
    margin: 0 5%;
  }

  .help-content-container {
    width: 90%;
  }

  #dashboard-tabs {
    width: 200px;
    margin: 0 5%;
  }

  .add-course-form {
    margin: 0 7%;
  }
}

#student-feedback-title-new {
  display: none;
}

@media (max-width: 450px) {
  #student-feedback-title {
    display: none;
  }

  #student-feedback-title-new {
    display: block;
  }
}

@media (max-width: 450px) {
  .help-section-box p {
    display: none;
  }
}

.nav-link.active {
  color: #8b0066 !important ;
  font-weight: bold;
}

.nav-link {
  color: #777777 !important ;
}

/*dark mode*/
body.dark-mode #course-filter-dropdown {
  background-color: #1a1a1a;
  color: #ffffff;
  border: 2px solid #444444;
}

body.dark-mode #course-filter-dropdown:hover {
  background-color: #444444;
  color: #ffffff;
  border: 2px solid #444444;
}

body.dark-mode .back-to-course-redirect-button {
  background-color: transparent;
  color: #ffffff;
}

body.dark-mode .back-to-course-redirect-button:hover {
  color: #cccccc;
}

body.dark-mode .z-index-button {
  position: relative;
  z-index: 10;
}

body.dark-mode .content-container form {
  background-color: transparent;
  color: #ffffff;
}
body.dark-mode .content-container b {
  background-color: transparent;
  color: #000;
}
body.dark-mode .help-content-container {
  background-color: #1a1a1a;
  color: #ffffff;
}

body.dark-mode .feedback-form-container,
body.dark-mode .feedback-form-inst form,
body.dark-mode .feedback-list,
body.dark-mode .course-actions {
  background-color: #1a1a1a;
  color: #ffffff;
}

body.dark-mode .feedback-icons {
  gap: 5px;
}

body.dark-mode #dashboard-tabs {
  background-color: #1a1a1a;
  color: #ffffff;
  border: 1px solid #444444;
}

body.dark-mode .add-course-form {
  background-color: #1a1a1a;
  color: #ffffff;
}

body.dark-mode #student-feedback-title {
  display: none;
}

body.dark-mode #student-feedback-title-new {
  display: block;
  color: #ffffff;
}

body.dark-mode .help-section-box p {
  color: #ffffff;
}

body.dark-mode .nav-link {
  color: #cccccc !important;
}

body.dark-mode .nav-link.active {
  color: #d17db7 !important;
  font-weight: bold;
}

body.dark-mode #course-filter-dropdown {
  background-color: #2b2b2b; /* Dark background for the toggle */
  color: #ffffff; /* White text */
  border: 2px solid #444444; /* Subtle border */
}

body.dark-mode #course-filter-dropdown:hover {
  background-color: #444444; /* Slightly lighter on hover */
  color: #ffffff;
  border: 2px solid #444444;
}

body.dark-mode .dropdown-menu {
  background-color: #1a1a1a; /* Dark background for dropdown menu */
  border: 1px solid #444444; /* Subtle border */
}

body.dark-mode .dropdown-item {
  color: #ffffff; /* White text for items */
  background-color: transparent; /* Transparent background */
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode .dropdown-item:hover {
  background-color: #444444; /* Darker background on hover */
  color: #ffffff;
}

body.dark-mode .dropdown-item:focus {
  background-color: #555555; /* Focus effect for accessibility */
  color: #ffffff;
}

body.dark-mode .add-course-form {
  background-color: transparent !important;
}

body.dark-mode .course-actions {
  background-color: transparent !important;
}

body.dark-mode .feedback-form-inst {
  background-color: transparent !important;
}
body.dark-mode .back {
  background-color: transparent !important;
}

body.dark-mode .my-course-select {
  background-color: #b6b6b6; /* gray */
}

body.dark-mode .certificate-download-button {
  background-color: #b6b6b6; /* gray */
  border: 2px #b6b6b6 solid;
}

body.dark-mode .certificate-download-button:hover {
  background-color: transparent;
  color: #ffffff;
}

body.dark-mode .sort-button {
  background-color: #2b2b2b; /* Dark background for the toggle */
  color: #ffffff; /* White text */
  border: 2px solid #444444; /* Subtle border */
}

body.dark-mode .sort-button:hover {
  background-color: #a7a5a53d; /* Slightly lighter on hover */
  color: #ffffff;
  border: 2px solid #444444;
}

body.dark-mode .feedback-submit-button {
  color: #ffffff;
  border: 2px #2b2b2b solid;
  background-color: #2b2b2b;
}

body.dark-mode .feedback-submit-button:hover {
  background-color: transparent;
  color: #ffffff;
}

body.dark-mode .form-select {
  border: 2px #404040 solid;
  background-color: #191818;
  color: #e0dfdf;
}

body.dark-mode #dashboard-tabs-tab-analytics {
  background-color: transparent;
}
body.dark-mode #dashboard-tabs-tab-students {
  background-color: transparent;
}

body.dark-mode .container-fluid {
  color: #008bbab2;
}
body.dark-mode .course-progress {
  color: rgba(255, 255, 255, 0.88);
}

body.dark-mode .form-control {
  border: 2px #404040 solid !important;
  background-color: #191818 !important;
  color: #ebe6e6 !important;
}

body.dark-mode .form-control::placeholder {
  color: #ffffff6c !important;
}

body.dark-mode .upload-button {
  color: #ffffff;
  border: 2px #2b2b2b solid;
  background-color: #ffffff29;
}

body.dark-mode .upload-button:hover {
  background-color: #000;
  color: #ffffff;
}

/* .course-progress/* #d17db7 !important  */

.submit-btn-training:hover {
  background-color: #4c4c4c !important;
  border: 2px solid #4c4c4c !important;
}
.training-upload {
  background-color: transparent;
  color: #2b2b2b;
  border: 2px solid #2b2b2b;
  padding: 6px 13px;
  font-size: 15px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: right;
  right: 50%;
  cursor: pointer;
  margin-top: 100px;
  margin-left: 100px;
}

.training-upload:hover {
  cursor: pointer;
  background-color: #2b2b2b;
  color: #fff;
  border: 2px solid #2b2b2b;
}

/*dark mode*/
body.dark-mode .training-upload {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #444444;
}

body.dark-mode .training-upload:hover {
  background-color: #444444;
  color: #ffffff;
  border: 2px solid #444444;
}

.setting-btn-inst {
  background-color: transparent;
  color: #2b2b2b;
  border: 2px solid #2b2b2b;
  padding: 6px 13px;
  font-size: 15px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: right;
  right: 50%;
  cursor: pointer;
  margin-top: 10px;
  /* margin-left: 50px; */
}

.setting-btn-inst:hover {
  cursor: pointer;
  background-color: #2b2b2b;
  color: #fff;
  border: 2px solid #2b2b2b;
}

/*dark mode*/
body.dark-mode .setting-btn-inst {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff8c;
}

body.dark-mode .setting-btn-inst:hover {
  background-color: #0000005c;
  border: 2px solid #2e2e2e;
}

.modal-whole {
  padding: 200px;
}

.modal-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 80px;
}

.modal-title {
  text-align: center;
  padding-bottom: 0;
  font-weight: bold;
}

input[type='date'] {
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
}

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1); /*calandar icon */
  cursor: pointer;
}

#interrupt_avatar {
  margin: 0px 4px;
  border-radius: 5px;
  padding: 0px 12px;
}

#reconnect {
  border-radius: 5px;
  padding: 0px 10px;
}

button[data-tooltip] {
  position: relative;
}

button[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 15px;
  white-space: nowrap;
  display: none;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
}

button[data-tooltip]:hover::after {
  display: block;
}

body.dark-mode #interrupt_avatar {
  background-color: transparent;
  color: #ffffff87;
  border: 2px solid #ffffff29;
  border-radius: 5px;
}

body.dark-mode #reconnect {
  background-color: transparent;
  color: #ffffff87;
  border: 2px solid #ffffff29;
  border-radius: 5px;
}

div[data-tooltip] {
  position: relative;
}

div[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 19px;
  left: 55%;
  bottom: 110%;

  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 15px;
  white-space: nowrap;
  display: none;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
}

div[data-tooltip]:hover::after {
  display: block;
}

button[data-tooltip] {
  position: relative;
}

button[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  display: none;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
}

button[data-tooltip]:hover::after {
  display: block;
}

@media (max-width: 400px) {
  #course-filter-dropdown {
    right: 20px;
    bottom: 10px;
  }
}
/*-------------------------------------------------------*/

.video-container {
  position: relative;
  flex-grow: 1;
  min-width: 400px;
  max-width: none;
}

.myProgress-chat-container {
  position: relative;
  overflow: visible;
  flex-grow: 1;
  min-width: 400px;
  max-width: none;
}

.myProgress-chat-container input,
.myProgress-chat-container button {
  pointer-events: auto;
}

.container-box {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  /* align-items: stretch; */
  justify-content: center;
  width: 100%;
}

body.dark-mode .container-box {
  background-color: #1e1e1e;
}

body.dark-mode .head {
  background-color: #1e1e1e;
}

body.dark-mode .myProgress-layout {
  background-color: #1e1e1e;
}
.myProgress-layout {
  width: 100%;
}

@media (min-width: 650px) {
  .container-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
  .video-container {
    position: absolute;
    top: 2px;
    right: 00px;
    width: 55rem;

    margin-left: 10px !important;
  }

  .loading-screen {
  }
}
@media (max-width: 649px) {
  .container-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #chatbox {
    height: 300px !important;
  }

  .video-container {
    width: 100%;
    max-width: 100%;
  }

  .myProgress-chat-container {
    width: 100%;
    max-width: 100%;
  }
}
