/* MyCoursesListStud.js */

.my-courses-list {
  text-align: left;
  margin: 0 10%;
}

.my-course-content {
  margin: 0;
  width: calc(100vw - 5%);
  position: relative;

  left: -10%;
}

.redo-icon {
  color: #777777;
  font-size: 20px;
  cursor: pointer;
}

.redo-icon:hover {
  color: #555555;
}

.course-redirect-button {
  width: 100px;
  margin: 0 auto;
  color: #ffffff;
  padding: 5px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px #2b2b2b solid;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.course-redirect-button:hover {
  background-color: transparent;
  color: #2b2b2b;
}
.hidden i {
  margin-right: 8px;
}
.hidden {
  font-family: 'Roboto', sans-serif;
}

.unhide-course-button {
  width: 100px;
  color: #ffffff;
  padding: 5px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px #2b2b2b solid;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.unhide-course-button:hover {
  background-color: transparent;
  color: #2b2b2b;
}
.unhide-course-button i {
  margin-right: 8px;
}

/* .hiddenCourses-wrapper {
  text-align: right;
  margin-right: 20px;
} */

.hiddenCourses:hover {
  background-color: #f0f0f0;
  color: #000;
}
.hiddenCourses {
  border-color: #555555;
}

.hiddenCourses i {
  margin-right: 8px;
}

.hiddenCourses span {
  margin-left: auto;
  font-size: 18px;
}

/* My CertificateList.js */

.my-certificate-list {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 576px) {
  .my-certificate-list,
  .my-courses-list {
    width: 90%;
    margin: 0 auto;
  }
}

.certificate-download-button {
  width: 140px;
  color: #2b2b2b;
  padding: 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px #2b2b2b solid;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.certificate-download-button i {
  margin-right: 8px;
  font-size: 18px;
}

.certificate-download-button:hover {
  color: white;
  background-color: #2b2b2b;
}

.sort-button {
  background-color: #2b2b2b;
  color: #fff;
  border: 2px solid #2b2b2b;
  padding: 4px 13px;
  font-size: 15px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sort-button:hover {
  background-color: transparent;
  color: #2b2b2b;
}

.dropdown-toggle::after {
  display: none !important;
}

/*dark mode*/
.btn .hiddenCourses {
  border: 1.3px solid #444444;
  color: #ffffff;
  background-color: transparent;
  padding: 8px 16px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.btn.hiddenCourses i {
  margin-right: 8px;
  transition: color 0.3s ease;
}

.btn.hiddenCourses.light-mode:hover {
  background-color: #f0f0f0;
  color: #000;
}

.btn.hiddenCourses.dark-mode {
  border: 1.2px solid #aaaaaa;
  color: #000;
  background-color: #2b2b2b;
}

.btn.hiddenCourses.dark-mode:hover {
  border-color: #ffffff;
  color: #2b2b2b;
  background-color: #ffffff;
}

.btn.hiddenCourses.dark-mode i {
  color: #ffffff;
}

.btn.hiddenCourses.dark-mode:hover i {
  color: #2b2b2b;
}

.go-to-page-button {
  color: #2b2b2b;
  padding: 5px 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px #2b2b2b solid;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.go-to-page-button:hover {
  color: white;
  background-color: #2b2b2b;
}

body.dark-mode .go-to-page-button {
  color: white;
  border: 2px white solid;

  background-color: #2b2b2b;
  cursor: pointer;
}

body.dark-mode .go-to-page-button:hover {
  background-color: rgba(255, 255, 255, 0.133);
}

/* Dark Mode Styling */
body.dark-mode .card {
  background-color: #2b2b2b;
  color: #ffffff;
  border: 1px solid #444444;
}

body.dark-mode .card-title {
  color: #ffffff;
}

body.dark-mode .card-subtitle {
  color: #c9c4c4 !important;
}

body.dark-mode .progress-bar {
  background-color: #4caf50;
}

body.dark-mode .btn.hiddenCourses {
  border: 1.2px solid #aaaaaa;
  color: #ffffff;
  background-color: #2b2b2b;
  padding: 8px 16px;
  border-radius: 17px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

body.dark-mode .btn.hiddenCourses:hover {
  border-color: #ffffff;
  color: #2b2b2b;
  background-color: #ffffff;
}

body.dark-mode .unhide-course-button {
  color: #ffffffe5;
  border: 2px #5f5e5e35 solid;
  background-color: #ffffff29;
}

body.dark-mode .unhide-course-button:hover {
  background-color: #ffffff41;
  color: #ffffff;
}

body.dark-mode .dropdown-menu {
  background-color: #1a1a1a;
  border: 1px solid #444444;
}

body.dark-mode .dropdown-item {
  color: #ffffff;
}

body.dark-mode .dropdown-item:hover {
  background-color: #444444;
  color: #ffffff;
}

body.dark-mode .fa-eye,
body.dark-mode .fa-eye-slash {
  color: #ffffff;
}

body.dark-mode .fa-ellipsis {
  color: #ffffff;
}

body.dark-mode .fa-rotate-right {
  color: #ffffff;
}

body.dark-mode .hiddenCourses-wrapper {
  text-align: right;
  margin-top: 20px;
}

body.dark-mode .course-redirect-button {
  color: #ffffffe5;
  border: 2px #5f5e5e35 solid;
  background-color: #ffffff29;
}

body.dark-mode .course-redirect-button:hover {
  background-color: #ffffff41;
  color: #ffffff;
}
