.student-navbar,
.instructor-navbar {
  padding: 10px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-header {
  display: none;
}

.menu-icon {
  color: #2b2b2b;
  font-size: 26px;
  cursor: pointer;
  display: none;
}

.student-navbar-list,
.instructor-navbar-list {
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 9999;
  display: flex;
}

.student-navbar-list li,
.instructor-navbar-list li {
  margin: 0 25px;
}

.student-navbar-list a,
.instructor-navbar-list a {
  color: #2b2b2b;
  font-size: 17px;
  font-weight: 500;
  display: block;
  padding: 5px 15px;
  border-radius: 50px;
  text-decoration: none;
  background-color: transparent;
}

.student-navbar-list .active,
.instructor-navbar-list .active {
  background-color: rgba(139, 0, 103, 0.4);
  color: white;
  box-shadow: 0 0 5px rgba(139, 0, 103, 0.5);
}

.student-navbar-list a:hover,
.instructor-navbar-list a:hover {
  background-color: rgba(139, 0, 103, 0.1);
  color: rgba(139, 0, 103, 0.9);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.student-navbar-list .active:hover,
.instructor-navbar-list .active:hover {
  background-color: rgba(139, 0, 103, 0.45);
  color: white;
}

@media (max-width: 1120px) {
  .navbar-header {
    display: block;
  }

  .student-navbar-list,
  .instructor-navbar-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    background-color: white;
    border-radius: 25px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease, box-shadow 0.6s ease;
  }

  .student-navbar-list.open,
  .instructor-navbar-list.open {
    max-height: 500px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .menu-icon {
    display: block;
    color: #2b2b2b;
    font-size: 26px;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease;
  }

  .menu-icon:hover {
    color: #4a4a4a;
  }

  .student-navbar-list li,
  .instructor-navbar-list li {
    margin: 10px;
  }

  .student-navbar-list a,
  .instructor-navbar-list a {
    font-size: 14px;
  }

  .student-navbar-list .active,
  .instructor-navbar-list .active {
    background-color: white;
    color: rgba(139, 0, 103, 0.8);
    box-shadow: none;
  }

  .student-navbar-list a:hover,
  .instructor-navbar-list a:hover {
    background-color: rgba(139, 0, 103, 0.1);
    color: rgba(139, 0, 103, 0.9);
    transition: background-color 0.3s ease, color 0.3s ease,
      backdrop-filter 0.3s ease;
  }

  .student-navbar-list .active:hover,
  .instructor-navbar-list .active:hover {
    background-color: rgba(139, 0, 103, 0.2);
    color: rgba(139, 0, 103, 1);
  }

  /* dark-mode */
  body.dark-mode .student-navbar-list,
  body.dark-mode .instructor-navbar-list {
    background-color: #2b2b2b;
    color: #f0f0f0;
  }

  body.dark-mode .student-navbar-list.open,
  body.dark-mode .instructor-navbar-list.open {
    box-shadow: 0 4px 12px rgba(255, 255, 255, 0.15);
  }

  body.dark-mode .student-navbar-list .active,
  body.dark-mode .instructor-navbar-list .active {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }
}

@media (max-width: 576px) {
  .menu-icon {
    left: calc(50% - 20px);
    transform: translateX(-50%);
  }
}

/* dark mode */
body.dark-mode .student-navbar,
body.dark-mode .instructor-navbar {
  background-color: #1e1e1e;
}

body.dark-mode .menu-icon {
  color: #f0f0f0;
}

body.dark-mode .student-navbar-list a,
body.dark-mode .instructor-navbar-list a {
  color: #f0f0f0;
  background-color: transparent;
}

body.dark-mode .student-navbar-list .active,
body.dark-mode .instructor-navbar-list .active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

body.dark-mode .student-navbar-list a:hover,
body.dark-mode .instructor-navbar-list a:hover {
  background-color: rgba(255, 255, 255, 0.05);
  color: #f0f0f0;
}
