.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: white;
  padding: 40px 40px 5px 40px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  width: 30%;
  max-width: 600px;
  z-index: 1001;
  position: relative;
  text-align: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.1rem;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode .modal-background {
  background-color: rgba(0, 0, 0, 0.8); /* Darker overlay background */
}

body.dark-mode .modal-container {
  background-color: #2b2b2b; /* Dark background for the modal */
  color: #ffffff; /* White text */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1); /* Softer shadow */
  width: 30%;
  max-width: 600px;
  z-index: 1001;
  position: relative;
  text-align: center;
}

body.dark-mode .close-button {
  background: none;
  border: none;
  font-size: 1.1rem;
  color: #ffffff; /* White close button */
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode .close-button:hover {
  color: #d17db7; /* Accent color on hover */
}

/* 

#8b0066
#015eaa

*/

.modal-title {
  font-size: 2.2rem;
  font-weight: 500;
  padding-bottom: 30px;
  margin: 0 0 60px 0;
  color: #6a1b9a;
  background: linear-gradient(
    to right,
    rgba(139, 0, 103, 0.6),
    rgba(139, 0, 103, 0.4),
    rgba(139, 0, 103, 0.4),
    rgba(1, 93, 170, 0.4),
    rgba(1, 93, 170, 0.4),
    rgba(1, 93, 170, 0.5)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;
}

.modal-text {
  font-size: 1rem;
  color: #777777;
}

.modal-contact-text {
  font-size: 0.9rem;
  color: #777777;
}

.modal-contact-text a {
  color: #777777;
  text-decoration: none;
}

.modal-contact-text a:hover {
  text-decoration: underline;
}

.google-signin {
  margin: 10px 0 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*dark mode*/
body.dark-mode .modal-title {
  color: #ffffffd3; /* White text for dark mode */
  background: none; /* Remove gradient background for dark mode */
  -webkit-text-fill-color: #ffffff; /* Ensure text is white */
}

body.dark-mode .modal-text {
  font-size: 1rem;
  color: #cccccc; /* Light gray text for better contrast in dark mode */
}

body.dark-mode .modal-contact-text {
  font-size: 0.9rem;
  color: #cccccc; /* Light gray text for contact information */
}

body.dark-mode .modal-contact-text a {
  color: rgb(134, 64, 115); /* Light sky blue for links */
  text-decoration: none;
}

body.dark-mode .modal-contact-text a:hover {
  text-decoration: underline;
}

body.dark-mode .google-signin {
  margin: 10px 0 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

/* Content Modal */

.content-modal-container {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-height: 90vh;
  overflow: hidden;
  z-index: 1001;
  position: relative;
  text-align: left;
}

.course-content-inst-view {
  max-height: calc(90vh - 80px);
  overflow-y: auto;
}
/*pass fail - need to check */
body.dark-mode .content-modal-container {
  background-color: #2b2b2b; /* Dark background for the modal */
  color: #ffffff; /* White text */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1); /* Softer shadow */
  width: 80%;
  max-height: 90vh;
  overflow: hidden;
  z-index: 1001;
  position: relative;
  text-align: left;
}

body.dark-mode .course-content-inst-view {
  max-height: calc(90vh - 80px);
  overflow-y: auto;
  background-color: #1a1a1a; /* Dark background for content scrolling area */
  color: #ffffff; /* White text for content */
  padding: 10px; /* Add padding for better readability */
  border-radius: 5px;
}

/* Profile Modal */

.profile-modal-content {
  max-width: 600px;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  max-height: 80%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 30%;
}

.profile-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile-label {
  padding: 10px 30px 5px 0;
  width: 120px;
  vertical-align: top;
  font-weight: 500;
  color: #777777;
  text-align: right;
}

.profile-value {
  padding: 10px 0 5px 0;
  text-align: left;
  color: #2b2b2b;
  flex: 1;
}

.profile-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  width: 400px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-value {
  text-align: left;
  word-break: break-word;
}

body.dark-mode .profile-modal-content {
  background-color: #2b2b2b; /* Dark background for modal */
  color: #ffffff; /* White text */
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  width: 400px;
  position: relative;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1); /* Softer shadow for dark mode */
}

body.dark-mode .profile-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

body.dark-mode .profile-label {
  padding: 10px 30px 5px 0;
  width: 120px;
  vertical-align: top;
  font-weight: 500;
  color: #cccccc; /* Light gray text for labels */
  text-align: right;
}

body.dark-mode .profile-value {
  padding: 10px 0 5px 0;
  text-align: left;
  color: #ffffff; /* White text for values */
  word-break: break-word;
  flex: 1;
}

/* Help Modal */

.help-modal {
  position: fixed;
  z-index: 1001;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 500px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.help-modal-content {
  position: relative;
  height: 80%;
  text-align: left;
}

.help-modal-content h2 {
  margin: 10px 0 40px 0;
  text-align: center;
}

.help-close-button {
  position: absolute;
  top: -25px;
  right: -10px;
  background: none;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode .help-modal {
  position: fixed;
  z-index: 1001;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 500px;
  background-color: #2b2b2b; /* Dark background for modal */
  color: #ffffff; /* White text */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.2); /* Softer shadow for dark mode */
}

body.dark-mode .help-modal-content {
  position: relative;
  height: 80%;
  text-align: left;
  color: #ffffff; /* White text for content */
}

body.dark-mode .help-modal-content h2 {
  margin: 10px 0 40px 0;
  text-align: center;
  color: #ffffff; /* White text for heading */
}

body.dark-mode .help-close-button {
  position: absolute;
  top: -25px;
  right: -10px;
  background: none;
  border: none;
  font-size: 1.1rem;
  color: #ffffff; /* White close button */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode .help-close-button:hover {
  color: #d17db7; /* Accent color on hover */
}

/* Quiz Pass Modal  */

.bg-quiz-result-bar {
  background-image: linear-gradient(
    135deg,
    rgba(139, 0, 103, 0.4),
    rgba(1, 93, 170, 0.4)
  ) !important;
  background-color: white !important;
}

.quiz-pass-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.quiz-pass-modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 40px 30px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.quiz-pass-modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

.quiz-pass-subtitle {
  font-size: 20px;
  margin-bottom: 12px;
}

.quiz-pass-score {
  font-size: 16px;
  margin-bottom: 20px;
}

.quiz-pass-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.go-to-achievements-button {
  color: #2b2b2b;
  background-color: transparent;
  width: 200px;
  padding: 5px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 2px solid #2b2b2b;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.go-to-achievements-button:hover {
  color: #ffffff;
  background-color: #2b2b2b;
}

.transcript-explanation {
  margin-top: 30px;
  font-size: 14px;
  color: #777777;
  margin-bottom: 0px;
}

.quiz-pass-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/*need to check*/

body.dark-mode .bg-quiz-result-bar {
  background-image: linear-gradient(
    135deg,
    rgba(139, 0, 103, 0.7),
    rgba(1, 93, 170, 0.7)
  ) !important;
  background-color: #2b2b2b !important; /* Dark background */
}

body.dark-mode .quiz-pass-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Darker overlay background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

body.dark-mode .quiz-pass-modal-content {
  background-color: #2b2b2b; /* Dark modal background */
  color: #ffffff; /* White text */
  border-radius: 8px;
  padding: 40px 30px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.2); /* Softer shadow */
  text-align: center;
  position: relative;
}

body.dark-mode .quiz-pass-modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #ffffff; /* White text */
}

body.dark-mode .quiz-pass-subtitle {
  font-size: 20px;
  margin-bottom: 12px;
  color: #cccccc; /* Light gray text */
}

body.dark-mode .quiz-pass-score {
  font-size: 16px;
  margin-bottom: 20px;
  color: #ffffff; /* White text */
}

body.dark-mode .quiz-pass-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

body.dark-mode .go-to-achievements-button {
  color: #ffffff; /* White text */
  background-color: transparent;
  border: 2px solid #ffffff; /* White border */
  width: 200px;
  padding: 5px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

body.dark-mode .go-to-achievements-button:hover {
  color: #2b2b2b; /* Dark text on hover */
  background-color: #ffffff; /* White background on hover */
}

body.dark-mode .transcript-explanation {
  margin-top: 30px;
  font-size: 14px;
  color: #cccccc; /* Light gray text */
  margin-bottom: 0px;
}

body.dark-mode .quiz-pass-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: #ffffff; /* White close button */
  cursor: pointer;
  transition: color 0.3s ease;
}

body.dark-mode .quiz-pass-close-button:hover {
  color: #d17db7; /* Accent color on hover */
}

/* Quiz Fail Modal */

.quiz-fail-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.quiz-fail-modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 500px;
  text-align: center;
  position: relative;
}

.quiz-fail-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.quiz-fail-retake-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .module-quiz-performance {
    display: none;
  }
}

@media (max-width: 1024px) {
  .modal-title,
  .help-modal-content h2 {
    font-size: 2rem;
  }

  .modal-container,
  .profile-modal-content {
    padding: 30px;
    width: 40%;
  }

  .help-modal {
    padding: 30px;
    width: 60%;
  }

  .close-button,
  .help-close-button {
    font-size: 1.3rem;
  }

  .modal-text,
  .profile-value {
    font-size: 0.95rem;
  }

  .modal-contact-text {
    font-size: 0.85rem;
  }

  .profile-label {
    padding: 8px 20px 5px 0;
    font-size: 0.95rem;
  }

  .content-modal-container {
    width: 90%;
    padding: 30px;
  }

  .google-signin {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}

@media (max-width: 576px) {
  .modal-title,
  .help-modal-content h2 {
    font-size: 1.8rem;
  }

  .modal-container,
  .profile-modal-content,
  .help-modal {
    padding: 20px;
    width: 85%;
  }

  .close-button {
    font-size: 1.3rem;
    top: 10px;
    right: 10px;
  }

  .modal-text,
  .profile-value {
    font-size: 0.9rem;
  }

  .google-signin {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .modal-contact-text {
    font-size: 0.8rem;
  }

  .profile-label {
    padding: 5px 15px 5px 0;
    width: 100px;
    font-size: 0.85rem;
  }

  .help-close-button {
    font-size: 1.3rem;
    top: -20px;
    right: -5px;
  }

  .content-modal-container {
    width: 90%;
    padding: 30px;
  }
}

body.dark-mode .quiz-fail-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Darker background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

body.dark-mode .quiz-fail-modal-content {
  background-color: #2b2b2b; /* Dark modal background */
  border-radius: 8px;
  padding: 20px;
  width: 500px;
  text-align: center;
  position: relative;
  color: #ffffff; /* White text */
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.2); /* Softer shadow */
}

body.dark-mode .quiz-fail-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #ffffff; /* White close button */
  transition: color 0.3s ease;
}

body.dark-mode .quiz-fail-close-button:hover {
  color: #d17db7; /* Accent color on hover */
}

body.dark-mode .quiz-fail-retake-button {
  background-color: #444444; /* Dark button background */
  color: #ffffff; /* White text */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode .quiz-fail-retake-button:hover {
  background-color: #ffffff; /* Bright background on hover */
  color: #2b2b2b; /* Dark text on hover */
}

@media (max-width: 1100px) {
  body.dark-mode .module-quiz-performance {
    display: none;
  }
}

@media (max-width: 1024px) {
  body.dark-mode .modal-title,
  body.dark-mode .help-modal-content h2 {
    font-size: 2rem;
  }

  body.dark-mode .modal-container,
  body.dark-mode .profile-modal-content {
    padding: 30px;
    width: 40%;
  }

  body.dark-mode .help-modal {
    padding: 30px;
    width: 60%;
  }

  body.dark-mode .close-button,
  body.dark-mode .help-close-button {
    font-size: 1.3rem;
  }

  body.dark-mode .modal-text,
  body.dark-mode .profile-value {
    font-size: 0.95rem;
    color: #cccccc; /* Light gray for better readability */
  }

  body.dark-mode .modal-contact-text {
    font-size: 0.85rem;
    color: #cccccc; /* Light gray for contact text */
  }

  body.dark-mode .profile-label {
    padding: 8px 20px 5px 0;
    font-size: 0.95rem;
    color: #cccccc; /* Light gray for labels */
  }

  body.dark-mode .content-modal-container {
    width: 90%;
    padding: 30px;
  }
}

@media (max-width: 576px) {
  body.dark-mode .modal-title,
  body.dark-mode .help-modal-content h2 {
    font-size: 1.8rem;
  }

  body.dark-mode .modal-container,
  body.dark-mode .profile-modal-content,
  body.dark-mode .help-modal {
    padding: 20px;
    width: 85%;
  }

  body.dark-mode .close-button {
    font-size: 1.3rem;
    top: 10px;
    right: 10px;
    color: #ffffff; /* White text */
  }

  body.dark-mode .modal-text,
  body.dark-mode .profile-value {
    font-size: 0.9rem;
    color: #cccccc; /* Light gray text */
  }

  body.dark-mode .modal-contact-text {
    font-size: 0.8rem;
    color: #cccccc; /* Light gray text */
  }

  body.dark-mode .profile-label {
    padding: 5px 15px 5px 0;
    width: 100px;
    font-size: 0.85rem;
    color: #cccccc; /* Light gray text */
  }

  body.dark-mode .help-close-button {
    font-size: 1.3rem;
    top: -20px;
    right: -5px;
    color: #ffffff; /* White text */
  }

  body.dark-mode .content-modal-container {
    width: 90%;
    padding: 30px;
  }
}
body.dark-mode .course-content-inst-view {
  background-color: transparent !important;
}
