.analytics-container {
  margin: 0 5%;
}

.quiz-analytics h3 {
  text-align: center;
  margin-bottom: 20px;
}

.selected-module {
  background-color: rgba(147, 112, 219, 0.2) !important;
  border-bottom: 2px solid #930067;
}

.expanded {
  border-bottom: 2px solid #930067;
}

.custom-dropdown {
  background-color: #f4f4f4;
  border: 1px solid #cccccc;
  font-size: 14px;
  padding: 6px 12px;
}

.custom-dropdown::after {
  margin-left: 5px;
}

.quiz-analytics-title {
  font-size: 24px;
  font-weight: bold;
}

.custom-dropdown-toggle {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 15px;
  padding: 0.5em 1.5em;
  font-weight: bold;
  color: #333333;
}

.custom-dropdown-toggle:hover {
  background-color: #f0f0f0;
  color: #333333;
}

.custom-dropdown-menu {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

body.dark-mode .custom-dropdown {
  background-color: #333333;
  border: 1px solid #555555;
  color: #ffffff;
}

body.dark-mode .custom-dropdown-toggle {
  background-color: #444444;
  border: 1px solid #555555;
  color: #ffffff;
}

body.dark-mode .custom-dropdown-toggle:hover {
  background-color: #555555;
  color: #ffffff;
}

body.dark-mode .custom-dropdown-menu {
  background-color: #333333;
  border: 1px solid #555555;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

body.dark-mode .quiz-analytics h3 {
  color: #ffffff;
}

body.dark-mode .quiz-analytics-title {
  color: #ffffff;
}

body.dark-mode .selected-module {
  background-color: rgba(139, 0, 103, 0.4) !important;
  border-bottom: 2px solid #92557f;
}

/*dark*/

.analytics-container .card {
  background-color: transparent !important;
  box-shadow: none !important;
  color: inherit !important;
}

body.dark-mode .analytics-container .card {
  color: #ffffff !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

body.dark-mode {
  background-color: rgba(0, 0, 0, 0.95);
}

/* .dark-mode .course-progress td,
.dark-mode .course-progress td span {
  color: rgba(138, 50, 115, 0.8) !important; 
} */
